<template>
  <transition name="toggle">
    <div
      v-show="value"
      class="dialog-mask">
      <v-layout
        justify-center
        wrap
      >
        <v-flex
          xs12
          md2
        >
          <v-text-field
            v-model="layout.name"
            :rules="[rules.required]"
            class="font-weight-bold"
            label="布局名称"
          />
          <v-text-field
            v-model="layout.width"
            :rules="[rules.ge20]"
            class="font-weight-bold"
            label="宽"
            readonly
            @click="openLayoutWidthEdit"
          />
          <v-text-field
            v-model="layout.height"
            :rules="[rules.ge20]"
            class="font-weight-bold"
            label="高"
            readonly
            @click="openLayoutHeightEdit"
          />
          <v-btn
            class="mx-3"
            color="red"
            @click.prevent="showAddAdvert">
            <v-icon size="30">mdi-youtube-tv
            </v-icon>
            <span class="mx-2 font-weight-medium">广告位</span>
          </v-btn>
          <v-btn
            class="mx-3 font-weight-medium"
            color="green"
            @click.prevent="showAddImg">
            <v-icon size="30">mdi-file-image
            </v-icon>
            <span class="mx-2 font-weight-medium">图  &nbsp; 片</span>
          </v-btn>
          <v-btn
            class="mx-3 font-weight-medium"
            color="blue"
            @click.prevent="showAddMedia">
            <v-icon size="30">mdi-video
            </v-icon>
            <span class="mx-2 font-weight-medium">视  &nbsp; 频</span>
          </v-btn>
          <v-btn
            class="mx-3 font-weight-medium"
            color="purple"
            @click.prevent="showAddText">
            <v-icon size="30">mdi-format-text
            </v-icon>
            <span class="mx-2 font-weight-medium">文  &nbsp; 字</span>
          </v-btn>
        </v-flex>
        <v-flex
          xs12
          md7>
          <div class="self-define-layout">
            <div
              :style="[styles,style]">
              <material-widget
                v-for="item in adveritems"
                :id="item.id"
                :key="item.id"
                :color="item.color"
                :title="item.title"
                :icon="item.icon"
                :x="getX(item)"
                :y="getY(item)"
                :width="getW(item)"
                :height="getH(item)"
                :min-width="item.minWidth"
                :min-height="item.minHeight"
                :active="item.active"
                class-name-handle="my-handle-class"
                @dragging="onDrag"
                @resizing="onResize"
                @click="showSettingDialog(item.id)"
                @activated="onActivated"
                @deactivated="onDeactivated"
              />
            </div>
          </div>
        </v-flex>
        <v-flex
          xs12
          md3
          style="padding-top: 0"
        >
          <v-btn
            class="font-weight-light"
            color="success"
            @click="updateLayout"
          >
            保存
          </v-btn>
          <v-btn
            class="font-weight-light"
            color="red"
            style="margin: 40px;"
            @click="cancel"
          >
            取消
          </v-btn>
          <material-card
            v-if="showhide.showSetting"
            color="green"
            title="组件设置"
            style="padding-top: 0"
          >
            <v-form>
              <v-container
                py-0
                style="padding-top: 0">
                <v-layout wrap >
                  <v-flex
                    xs12
                    md12
                  >
                    <v-text-field
                      v-model="settingDialog.title"
                      class="font-weight-bold"
                      label="title"
                      @change="titleChange"/>
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                  >
                    <v-text-field
                      v-model="settingDialog.x"
                      :rules="[rules.ge]"
                      label="x"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                  >
                    <v-text-field
                      v-model="settingDialog.y"
                      :rules="[rules.ge]"
                      label="y"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                  >
                    <v-text-field
                      v-model="settingDialog.width"
                      :rules="[rules.ge]"
                      label="width"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    md6
                  >
                    <v-text-field
                      v-model="settingDialog.height"
                      :rules="[rules.ge]"
                      label="height"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    md12
                  >
                    <v-btn
                      v-if="showhide.deleteBtn"
                      class="mx-1 font-weight-light"
                      color="success"
                      @click="deleteWidget(settingDialog.id)"
                    >
                      删除组件
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </material-card>
        </v-flex>

      </v-layout>
    </div>
  </transition>
</template>

<script>
const WIDTH = 800
const HEIGHT = 800
export default {
  name: 'LayoutAdd',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: 0,
      showhide: {
        showSetting: false,
        layoutWidth: false,
        layoutHeight: false,
        deleteBtn: false
      },
      layout: {
        id: '',
        name: '布局',
        width: 1920,
        height: 1080,
        canvaswidth: 0,
        canvasheight: 0,
        editwidth: 0,
        editheight: 0,
        editname: ''
      },
      settingDialog: {
        id: '',
        x: '',
        y: '',
        width: '',
        height: '',
        title: ''
      },
      rules: {
        required: value => !!value || '此为必填项',
        ge: value => value >= 0 || '必须>=0',
        ge20: value => value >= 20 || '必须>=20',
        ge100: value => value >= 100 || '必须>=100',
        min: v => v.length >= 8 || 'Min 8 characters'
      },
      active: false,
      index: 0,
      adindex: 0,
      imgindex: 0,
      mediaindex: 0,
      textindex: 0,
      adveritems: [],
      textes: [],
      selectedShapeName: '',
      style: {
        'background': '#a7c1ca',
        'position': 'relative',
        'margin': '0 auto',
        'top': '50%',
        'transform': 'translateY(-50%)'
      }
    }
  },
  computed: {
    styles: function () {
      // console.log('styles!!!!!!', this.layout.width, this.layout.height)
      let w = WIDTH
      let h = HEIGHT
      if (parseInt(this.layout.width) > parseInt(this.layout.height)) {
        return {
          'width': w + 'px',
          'height': Math.floor(this.layout.height * (w / this.layout.width)) + 'px'
        }
      } else {
        return {
          'width': Math.floor(this.layout.width * (h / this.layout.height)) + 'px',
          'height': h + 'px'
        }
      }
    }
  },
  watch: {
    value (newVal, oldVal) {
      // console.log('value() ', newVal, oldVal)
      this.renew(newVal)
    }
  },
  created () {
    // console.log('created(): ')
  },
  mounted () {
    // console.log('mounted(): ')
  },
  methods: {
    getX (item) {
      // console.log('getX() ', item)
      return Math.floor(item.x * this.layout.canvaswidth / this.layout.width)
    },
    getY (item) {
      return Math.floor(item.y * this.layout.canvasheight / this.layout.height)
    },
    getW (item) {
      return Math.floor(item.width * this.layout.canvaswidth / this.layout.width)
    },
    getH (item) {
      return Math.floor(item.height * this.layout.canvasheight / this.layout.height)
    },
    renew (value) {
      // console.log('renew() ', value)
      if (value) {
      }
    },
    init (obj, id) {
      // console.log('init() ', obj)
      this.id = id
      this.adindex = 0
      this.imgindex = 0
      this.mediaindex = 0
      this.textindex = 0
      this.layout.id = obj.id
      this.layout.name = obj.layoutName
      this.layout.width = obj.width
      this.layout.height = obj.height
      this.adveritems = JSON.parse(obj.layoutJson)
      this.index = this.adveritems.length
      for (let i = 0; i < this.adveritems.length; i++) {
        if (this.adveritems[i].wid.indexOf('ad') !== -1) {
          this.adveritems[i].color = 'red'
          this.adveritems[i].icon = 'mdi-youtube-tv'
          this.adveritems[i].minWidth = 20
          this.adveritems[i].minHeight = 20
          this.adindex++
        }
        if (this.adveritems[i].wid.indexOf('img') !== -1) {
          this.adveritems[i].color = 'green'
          this.adveritems[i].icon = 'mdi-file-image'
          this.adveritems[i].minWidth = 20
          this.adveritems[i].minHeight = 20
          this.imgindex++
        }
        if (this.adveritems[i].wid.indexOf('media') !== -1) {
          this.adveritems[i].color = 'blue'
          this.adveritems[i].icon = 'mdi-video'
          this.adveritems[i].minWidth = 20
          this.adveritems[i].minHeight = 20
          this.mediaindex++
        }
        if (this.adveritems[i].wid.indexOf('text') !== -1) {
          this.adveritems[i].color = 'purple'
          this.adveritems[i].icon = 'mdi-format-text'
          this.adveritems[i].minWidth = 20
          this.adveritems[i].minHeight = 20
          this.textindex++
        }
      }
      if (parseInt(this.layout.width) > parseInt(this.layout.height)) {
        this.layout.canvaswidth = WIDTH
        this.layout.canvasheight = Math.floor(this.layout.height * this.layout.canvaswidth / this.layout.width)
      } else {
        this.layout.canvasheight = HEIGHT
        this.layout.canvaswidth = Math.floor(this.layout.width * this.layout.canvasheight / this.layout.height)
      }
    },
    showSettingDialog: function (id) {
      if (this.adveritems[id].wid.indexOf('ad') !== -1) {
        this.showhide.deleteBtn = false
      } else {
        this.showhide.deleteBtn = true
      }
      this.settingDialog.id = id
      this.settingDialog.x = this.adveritems[id].x
      this.settingDialog.y = this.adveritems[id].y
      this.settingDialog.width = this.adveritems[id].width
      this.settingDialog.height = this.adveritems[id].height
      this.settingDialog.title = this.adveritems[id].title
      this.showhide.showSetting = true
    },
    openLayoutWidthEdit: function () {
      this.showhide.layoutWidth = true
      this.layout.editwidth = this.layout.width
    },
    updateLayoutWidth: function () {
      if (this.layout.editwidth < 100) {
        this.$snackbar.info('宽度不能小于100')
        return
      }
      this.showhide.layoutWidth = false
      this.layout.width = this.layout.editwidth
    },
    openLayoutHeightEdit: function () {
      this.showhide.layoutHeight = true
      this.layout.editheight = this.layout.height
    },
    updateLayoutHeight: function () {
      if (this.layout.editheight < 100) {
        this.$snackbar.info('高度不能小于100')
        return
      }
      this.showhide.layoutHeight = false
      this.layout.height = this.layout.editheight
    },
    closeSettingDialog: function () {
      this.showhide.showSetting = false
    },
    titleChange: function () {
      this.adveritems[this.settingDialog.id].title = this.settingDialog.title
    },
    onResize: function (id, x, y, width, height) {
      // console.log('onResize(): ', id, x, y, width, height)
      this.adveritems[id].x = Math.floor(x * this.layout.width / this.layout.canvaswidth)
      this.adveritems[id].y = Math.floor(y * this.layout.height / this.layout.canvasheight)
      this.adveritems[id].width = Math.ceil(width * this.layout.width / this.layout.canvaswidth)
      this.adveritems[id].height = Math.ceil(height * this.layout.width / this.layout.canvaswidth)

      this.settingDialog.x = this.adveritems[id].x
      this.settingDialog.y = this.adveritems[id].y
      this.settingDialog.width = this.adveritems[id].width
      this.settingDialog.height = this.adveritems[id].height
    },
    onDrag: function (id, x, y) {
      // console.log('onDrag(): ', id, x, y)
      this.adveritems[id].x = Math.floor(x * this.layout.width / this.layout.canvaswidth)
      this.adveritems[id].y = Math.floor(y * this.layout.height / this.layout.canvasheight)

      this.settingDialog.x = this.adveritems[id].x
      this.settingDialog.y = this.adveritems[id].y
    },
    onActivated () {
      this.active = true
    },
    onDeactivated () {
      this.active = false
    },
    showAddAdvert () {
      this.showhide.deleteBtn = false
      if (this.adindex > 0) {
        this.$snackbar.info('广告位只能添加1个')
        return
      }
      let ad = {
        id: this.index,
        wid: 'ad_' + this.adindex,
        color: 'red',
        title: '广告位' + (this.adindex + 1),
        icon: 'mdi-youtube-tv',
        x: 10,
        y: 10,
        width: 70,
        height: 70,
        minWidth: 20,
        minHeight: 20,
        active: true
      }
      this.adveritems.push(ad)
      this.adindex++
      this.index++
      this.showSettingDialog(ad.id)
    },
    showAddImg () {
      this.showhide.deleteBtn = true
      let img = {
        id: this.index,
        wid: 'img_' + this.imgindex,
        color: 'green',
        title: '图片' + (this.imgindex + 1),
        icon: 'mdi-file-image',
        x: 10,
        y: 10,
        width: 70,
        height: 70,
        minWidth: 20,
        minHeight: 20,
        active: true
      }
      this.adveritems.push(img)
      this.imgindex++
      this.index++
      this.showSettingDialog(img.id)
    },
    showAddMedia () {
      this.showhide.deleteBtn = true
      let media = {
        id: this.index,
        wid: 'media_' + this.mediaindex,
        color: 'blue',
        title: '视频' + (this.mediaindex + 1),
        icon: 'mdi-video',
        x: 10,
        y: 10,
        width: 70,
        height: 70,
        minWidth: 20,
        minHeight: 20,
        active: true
      }
      this.adveritems.push(media)
      this.mediaindex++
      this.index++
      this.showSettingDialog(media.id)
    },
    showAddText () {
      this.showhide.deleteBtn = true
      let text = {
        id: this.index,
        wid: 'text_' + this.textindex,
        color: 'purple',
        title: '文字' + (this.textindex + 1),
        icon: 'mdi-format-text',
        x: 10,
        y: 10,
        width: 100,
        height: 50,
        minWidth: 20,
        minHeight: 20,
        active: true
      }
      this.adveritems.push(text)
      this.textindex++
      this.index++
      this.showSettingDialog(text.id)
    },
    deleteWidget: function (id) {
      this.adveritems.splice(id, 1)
      for (let i = 0; i < this.adveritems.length; i++) {
        this.adveritems[i].id = i
      }
      this.index--
      this.closeSettingDialog()
      this.settingDialog.width = ''
      this.settingDialog.height = ''
      this.settingDialog.x = ''
      this.settingDialog.y = ''
      this.settingDialog.title = ''
    },
    addLayout () {
      if (!this.layout.name) {
        this.$snackbar.info('布局名称不能为空！')
        return
      }
      // if (this.adveritems.length <= 0) {
      //   this.$snackbar.info('布局不能为空！')
      //   return
      // }
      let layoutJson = ''
      let layoutArr = []
      for (let i = 0; i < this.adveritems.length; i++) {
        layoutArr.push(this.adveritems[i])
      }
      layoutJson = JSON.stringify(layoutArr)
      let formdata = new FormData()
      formdata.append('name', this.layout.name)
      formdata.append('width', this.layout.width)
      formdata.append('height', this.layout.height)
      formdata.append('json', layoutJson)
      this.$http.post(this.$store.state.app.url + '/mediaSystem/layout/addLayout', formdata, this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            this.$snackbar.success(res.message)
          } else {
            this.$snackbar.error(res.message)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    cancel: function () {
      this.$emit('viewClosed')
    },
    updateLayout: function () {
      // console.log(this.adveritems[0])
      // return
      if (!this.layout.name) {
        this.$snackbar.info('布局名称不能为空')
        return
      }
      // if (this.adveritems.length <= 0) {
      //   this.$snackbar.info('布局不能为空')
      //   return
      // }
      let layoutJson = ''
      let layoutArr = []
      for (let i = 0; i < this.adveritems.length; i++) {
        let item = {}
        item.id = this.adveritems[i].id
        item.wid = this.adveritems[i].wid
        item.x = this.adveritems[i].x
        item.y = this.adveritems[i].y
        item.width = this.adveritems[i].width
        item.height = this.adveritems[i].height
        item.title = this.adveritems[i].title
        layoutArr.push(item)
      }
      layoutJson = JSON.stringify(layoutArr)
      let formdata = new FormData()
      formdata.append('id', this.layout.id)
      formdata.append('name', this.layout.name)
      formdata.append('width', this.layout.width)
      formdata.append('height', this.layout.height)
      formdata.append('json', layoutJson)
      this.$http.post(this.$store.state.app.url + '/mediaSystem/layout/updateLayout', formdata, this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            // this.$snackbar.success(res.message)
            // console.log(res.detail)
            this.$emit('viewClosed', res.detail, this.id)
          } else {
            this.$snackbar.error(res.message)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .dialog-mask {
    background-color: #eeeeee;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    display: table;
    animation-duration: 0s;
  }
  .text-event {
    position: absolute;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .self-define-layout {
    height: 800px;
    width: 800px;
    background: #cccccc;
    margin: 0 auto;
  }
  .my-handle-class {
    position: absolute;
    background-color: pink;
    border: 1px solid black;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    box-model: border-box;
    -webkit-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
  }
</style>
