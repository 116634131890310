<template>
  <div class="self-define-layout">
    <div
      :style="[styles,style]">
      <material-widget
        v-for="item in layouts"
        :id="item.id"
        :key="item.id"
        :draggable="false"
        :resizable="false"
        :color="getColor(item)"
        :icon="getIcon(item)"
        :x="getX(item)"
        :y="getY(item)"
        :width="getW(item)"
        :height="getH(item)"
        :min-width="1"
        :min-height="1"
        class-name-handle="my-handle-class"
        @click="onclick"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutPreview',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: [Number, String],
      default: 200,
      validator: function validator (val) {
        return val > 0
      }
    },
    height: {
      type: [Number, String],
      default: 200,
      validator: function validator (val) {
        return val > 0
      }
    },
    click: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      layouts: [],
      previewWidth: 0,
      previewHeight: 0,
      style: {
        'background': '#a7c1ca',
        'position': 'relative',
        'margin': '0 auto',
        'top': '50%',
        'transform': 'translateY(-50%)'
      }
    }
  },
  computed: {
    styles: function () {
      if (parseInt(this.value.width) > parseInt(this.value.height)) {
        return {
          'width': this.width + 'px',
          'height': Math.floor(this.value.height * (this.width / this.value.width)) + 'px'
        }
      } else if (parseInt(this.value.width) < parseInt(this.value.height)) {
        return {
          'width': Math.floor(this.value.width * (this.height / this.value.height)) + 'px',
          'height': this.height + 'px'
        }
      } else {
        return {
          'width': this.width + 'px',
          'height': this.height + 'px'
        }
      }
    }
  },
  watch: {
    value (newVal, oldVal) {
      // console.log('value() ', newVal)
      // console.log('value() ', oldVal)
      if (!this.$isEmpty(newVal)) {
        this.layouts = JSON.parse(newVal.layoutJson)
      }
    },
    width: {
      type: [Number, String],
      default: 200,
      validator: function validator (val) {
        return val > 0
      }
    },
    height: {
      type: [Number, String],
      default: 200,
      validator: function validator (val) {
        return val > 0
      }
    }
  },
  created () {
    // console.log('created(): this.value: ', this.value)
    if (!this.$isEmpty(this.value)) {
      this.layouts = JSON.parse(this.value.layoutJson)
      // console.log('created(): this.layouts: ', this.layouts)
      if (this.value.width > this.value.height) {
        this.previewWidth = this.width
        this.previewHeight = this.previewWidth * (this.value.height / this.value.width)
      } else {
        this.previewHeight = this.height
        this.previewWidth = this.previewHeight * (this.value.width / this.value.height)
      }
    }
  },
  mounted () {
    // console.log('today2: ', this.today)
  },
  methods: {
    getX (item) {
      return Math.floor(item.x * this.previewWidth / this.value.width)
    },
    getY (item) {
      return Math.floor(item.y * this.previewHeight / this.value.height)
    },
    getW (item) {
      return Math.floor(item.width * this.previewWidth / this.value.width)
    },
    getH (item) {
      return Math.floor(item.height * this.previewHeight / this.value.height)
    },
    getColor (item) {
      if (item.wid.indexOf('ad') !== -1) {
        return 'red'
      } else if (item.wid.indexOf('img') !== -1) {
        return 'green'
      } else if (item.wid.indexOf('media') !== -1) {
        return 'blue'
      } else if (item.wid.indexOf('text') !== -1) {
        return 'purple'
      }
      return 'yellow'
    },
    getIcon (item) {
      if (item.wid.indexOf('ad') !== -1) {
        return 'mdi-youtube-tv'
      } else if (item.wid.indexOf('img') !== -1) {
        return 'mdi-file-image'
      } else if (item.wid.indexOf('media') !== -1) {
        return 'mdi-video'
      } else if (item.wid.indexOf('text') !== -1) {
        return 'mdi-format-text'
      }
      return 'mdi-material-design'
    },
    onclick: function () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
  .my-handle-class {
    position: absolute;
    background-color: pink;
    border: 1px solid black;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    box-model: border-box;
    -webkit-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
  }
  .self-define-layout {
    height: 250px;
    width: 250px;
    background: #cccccc;
    margin: 0 auto;
  }
</style>
