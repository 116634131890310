<template>
  <v-container
    fill-height
    fluid
    style="padding-top: 0; flex-wrap: wrap;"
  >
    <layout-add
      v-if="addLayoutView"
      ref="addView"
      v-model="addLayoutViewShow"
      @viewClosed="hideDialog"
    />
    <v-card-title
      style="padding: 0"
    >
      <v-flex
        xs12
        md3
      >
        <v-btn
          class="mx-3  font-weight-medium"
          color="green"
          @click.prevent="">
          <v-icon>mdi-plus-box-outline</v-icon>
          <span class="mx-2 font-weight-medium">添加布局</span>
        </v-btn>
      </v-flex>
    </v-card-title>
    <material-card
      color="green"
      style="margin-top: 30px">
      <div
        slot="header"
        class="d-flex">
        <span>
          <div class="title mb-2">
            布局信息
          </div>
        </span>
      </div>
      <v-layout
        wrap
      >
        <v-flex
          v-for="(item, index) in listitems"
          :key="item.id"
          xs12
          md3
          style="margin-bottom: 40px;"
        >
          <div
            class="model-layout"
            @click="openEdit(item, index)">
            <layout-preview
              v-model="listitems[index]"
              :width="250"
              :height="250"
              @click="openEdit(item, index)"
            />
            <p>{{ item.layoutName }}#{{ item.width }} x {{ item.height }}</p>
          </div>
        </v-flex>
      </v-layout>
    </material-card>
  </v-container>
</template>

<script>
import Vue from 'vue'
import layoutAdd from './LayoutAdd'
import layoutPreview from './LayoutPreview'
Vue.component('layout-add', layoutAdd)
Vue.component('layout-preview', layoutPreview)
// const width = window.innerWidth
// const height = window.innerHeight
export default {
  data () {
    return {
      addLayoutView: true,
      addLayoutViewShow: false,
      listitems: []
    }
  },
  computed: {
  },
  created () {
    this.layoutlist()
  },
  methods: {
    layoutlist: function () {
      this.listitems = []
      this.$http.get(this.$store.state.app.url + '/mediaSystem/layout/layoutList?page=1&size=8', this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            this.listitems = res.detail.list
            // console.log('this.listitems: ', this.listitems)
          } else {
            this.$snackbar.error(res.message)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    hideDialog: function (detail, id) {
      // console.log('hideDialog()', detail, id)
      if (!this.$isEmpty(detail) && !this.$isEmpty(id)) {
        this.listitems[id] = detail
      }
      this.addLayoutView = false
      this.addLayoutViewShow = false
      this.$nextTick(() => {
        this.addLayoutView = true
      })
    },
    openEdit: function (obj, id) {
      // console.log('hideDialog()', obj, id)
      this.addLayoutViewShow = true
      this.$refs.addView.init(obj, id)
    }
  }
}
</script>
<style scoped>
  .model-layout {
    float: left;
    height: 250px;
    width: 250px;
    background: #a7c1ca;
    position: relative;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
